* {
    padding: 0;
    margin: 0;
}

.menu {
    padding-right: 10%;
    padding-left: 10%;
}

.ln {
    transition: all 0.25s;
}

.ln:hover {
    color: rgb(119, 119, 255);
    text-shadow: 0px 0px 1em rgb(30, 30, 158);
}

.title {
    text-align: center;
    padding: 0.8em;
    background: rgb(119, 119, 255);
    color: white;
}

.titleOne {
    margin-top: 10px;
    text-align: center;
    padding: 0.8em;
    background: rgb(119, 119, 255);
    color: white;
}

.text {
    background: rgb(119, 119, 255, 0.8);
    border: 2px solid white;
    color: white;
    width: 45%;
    margin: auto;
    height: 100px;
    margin-bottom: 30px
}

.link {
    color: white;
    text-decoration: none;
}

.link:hover {
    color: #d9d9d9;
}

.cards {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: stretch;
    gap: 1em;
    flex-wrap: wrap;
    margin: 10px 0 10px 0;

}

.subscription {
    margin: 30px 30% 30px 30%;
}

.post {
    transition: all 0.25s;
}

.post:hover {
    border: 1px solid rgb(119, 119, 255);
    box-shadow: 0px 0px 10px 0px rgb(119, 119, 255);
}

.footer {
    background: rgb(119, 119, 255);
    color: white;
    padding: 1em 10% 1em 10%;
    width: 100%;
}

.footer p {
    margin: 0;
}

.subscribe {
    border: 1px solid rgb(119, 119, 255);
    margin-top: 10px;
    background: rgb(119, 119, 255);
}

.subscribe:hover {
    background: rgb(61, 61, 255);
}

.signin {
	margin-top: 30px;
	padding-right: 10%;
	padding-left: 10%;
}

.signup {
	margin-top: 30px;
	padding-right: 10%;
	padding-left: 10%;
}

.main {
    min-height: 80vh;
}

.none {
    display: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

@keyframes message1 {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.anim_mess {
    animation: message1 1s;
}

.nocards {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    text-align: center;
}

.imageAccount {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: top;
}

.imageCard {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: top;
}